/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;500;700&family=Poppins:wght@100;300;400;500&display=swap');

/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif; /* Default sans-serif font */
  background-color: #f9f5f0; /* soft beige background color */
  color: #60442b; 
}

h1 {
  font-family: 'Cormorant Garamond', serif; /* Updated to use Libre Baskerville */
  font-weight: 300;
  font-size: 2rem;
}

h2 {
  font-family: 'Cormorant Garamond', serif; /* Updated to use Libre Baskerville */
  font-weight: 300;
}

h3, h4, h5, h6 {
  font-family: 'Cormorant Garamond', serif; /* Updated to use Libre Baskerville */
  color: #60442b; 
  font-weight: 300;
}

a {
  text-decoration: none;
  color: inherit;
}

.italic {
  font-style: italic;
}
